<template>
  <div class="login_body">
    <div class="login_wrapper">
      <div class="login_formulario">
        <div class="login_titulo">
          FACTURACIÓN ELECTRÓNICA
        </div>
        <div class="login_texto">
          Identifiquemos la factura pos recibida, <br />ingresando los datos a continuación
        </div>
        <div class="login_border">
          <div v-if="if_formulario_buscando" class="login_buscando">
            <img src="/images/search.gif" style="width:180px" />
            <br />
            Estamos ubicando tu factura:
            <div class="text-bold">{{ prefijo }}-{{ factura }}</div>
            <br />
          </div>
          <div v-else>
            <form v-on:submit.prevent="getFactura">
              <table width="100%">
                <tr>
                  <td class="login-info">
                    Prefijo
                  </td>
                  <td class="login-info">
                    Consecutivo
                  </td>
                </tr>
                <tr>
                  <td width="40%">
                    <input type="text" v-model="prefijo" autocomplete="off" style="text-transform:uppercase"
                      onblur="value = value.toUpperCase()" />
                  </td>
                  <td>
                    <input type="text" v-model="factura" autocomplete="off" style="text-transform:uppercase"
                      onblur="value = value.toUpperCase()" @keypress="onlyNumber" />
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="login-info">
                    Código HASH de la factura (12 Digitos)
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <input type="text" v-model="hashfactura" autocomplete="off" style="text-transform:uppercase"
                      onblur="value = value.toUpperCase()" />
                  </td>
                </tr>

              </table>

              <div v-if="mensaje">
                <div class="login_error">
                  <h5>{{ mensaje }}</h5>
                </div>
              </div>
              <div v-else>
                <div class="login-info">
                  ¿Dónde esta el código HASH?, mire la imagen de ejemplo
                </div>


                <div class="bg-gray factura-round">
                  <img src="/images/factura.png" style="max-width: 70%;" />
                </div>

                <div class="login_error">
                  {{ mensaje }}
                </div>
              </div>

              <br />
              <button type="submit" class="btn btn-kubber btn-large"> Buscar</button>
            </form>
          </div>
        </div>
        <div style="font-size: 12px; color: red;">Version 1.6.0 | 2024-ABRL-05</div>

        <br />
        <a target="_blank" href="https://www.instagram.com/kubber.plataforma/" style="font-size: 10px; color: brown;">
          Siguenos en Instagram<br />
          <img src="/images/kubber-logo-transparente.png" style="height: 30px; opacity: 0.5;">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router";
import axios from "axios";
import GLOBAL from "../Global.js";

export default {
  name: "HomeView",
  components: {},
  data: function () {
    return {
      prefijo: "",
      factura: "",
      hashfactura: "",
      movimiento: null,
      error: false,
      mensaje: "",
      if_formulario_buscando: false
    };
  },
  created() {

  },

  methods: {

    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    getFactura() {


      var validacion = "OK"

      if (this.hashfactura.trim() == "") {
        this.hashfactura = this.hashfactura.trim()
        validacion = "Ingrese el hash de la factura"
      }

      if (this.prefijo.trim() == "") {
        validacion = "Ingrese el prefijo de factura"
      }

      if (this.factura.trim() == "") {
        validacion = "Ingrese el numero de factura"
      }



      if (validacion == "OK") {
        this.if_formulario_buscando = true
        this.otraFunction() //Asincronica
      } else {
        this.mensaje = validacion
      }
    },

    async otraFunction() {

      let url = GLOBAL.API2_GET_FACTURA_POR_HASH + "?numero=" + this.factura + "&hash=" + this.hashfactura.toUpperCase() + "&prefijo=" + this.prefijo.toUpperCase() + "&extended=true";


      axios.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Basic " + GLOBAL.BASIC_API,
          Environment: 0,
        },
      }).then((response) => {

        let json = response;
        console.log(json)
        this.movimiento = json.data;

        this.if_formulario_buscando = false


        router.push({ name: "invoice", params: { data: JSON.stringify(this.movimiento) } });
       

      }).catch((error) => {
        this.if_formulario_buscando = false
        console.log(error)
        try {
          if (error.response.status == 404) {
            this.mensaje = "Factura [" + this.prefijo +"-"+ this.factura + "] no fue encontrada"
          } else {
            this.mensaje = error.response.data.exception
          }
        } catch (error) {
          this.mensaje = "Factura no encontrada"
        }

      });


    }

  },
};

</script>

