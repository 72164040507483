//var SERVER_HOST = 'http://co.lkubber.co:8080' 
var SERVER_HOST = 'https://kubber.co:8843'

module.exports = {
    SESSION_KEY: "persona",
    STORE_KEY_RECAPTCHA: '6Lf_UTAfAAAAADVN2ugW8d8dz3HhvMWsd5JizE17',
    BASIC_API: 'd2Vic2VydmljZTAxOnRhcmdldHQyLjU',
    BASE_URL: 'https://gestionelectronica.co:11901',

    API_GET_LOGIN: '/comandos/api/parametros/login/',
    API_GET_CRITERIO: '/comandos/api/parametros/criterio/' + btoa('DOCUMENTO'),
    API_GET_TERCERO: '/comandos/api/parametros/terceros',
    API_GET_PAIS: '/comandos/api/parametros/paises',
    //API_GET_DEPARTAMENTOS: '/comandos/api/parametros/provincias/' + btoa('codepais'),
    API2_GET_DEPARTAMENTOS:  SERVER_HOST + "/kubber/servicios/api/v1/common/list/department",
    API2_GET_CIUDADES:  SERVER_HOST + "/kubber/servicios/api/v1/common/list/municipality",


    API2_GET_FACTURA_POR_HASH: SERVER_HOST + '/kubber/facturacion/api/v1/factura',
    API2_GET_FACTURA_A_ELECTRONICA_OLD: SERVER_HOST + '/kubber/facturacion/api/v1/convertir',
    API2_GET_FACTURA_A_ELECTRONICA: SERVER_HOST + '/kubber/facturacion/api/v1/crear',

    COLOR: {
        RED: "#C91C1E",
        BLUE: "#2273A8"
    },

    APIS_PLEMSIS: {
        IS_PRODUCCION: true,
        IS_GET_CIUDADES: true,
        API_PRUEBA:     "2850c2d3270289fb90daee9e",
        API_PRODUCCION: "e24b6d452cec95a0af38af58",
       //CIUDADES: "https://pruebas.plemsi.com/api/common/list/municipality"
        CIUDADES: "https://api.plemsi.com/api/common/list/municipality"
    },

    DASHBOARD_PERMISOS: {
        SCADARESUMEN: "cc8d2382-73c7-495b-8382-02db80acc592",
        SCADANODOS: "49a63cd0-95c1-4113-b46b-45f8ae6759e4",
        SCADAMAPA: "3f367976-9b8b-4bc1-a524-375c02597dc4",
        SCADACHART: "a7b10db1-7fda-48ae-b82a-e16ab424e8a6",
    },

    USUARIOS_PERMISOS: {
        LISTAR: "299f1564-8188-4dd8-b831-154c1fab8cda",
    },

    DASHBOARD: {
        NODOS: "dashboard.nodos",
        NODO: "dashboard.nodo"
    },

    MENSAJE: {
        SIN_AUTORIZACION: "Actualmente no cuenta con los permisos necesarios para ver o ejecutar esta función"
    },


    FORMATO_MONEY(number) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    },

    FORMATO_MONEY_BASIC(number) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(number);
    },

    FORMATO_NUMBER(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number);
    },



};






// Encode the String
//var encodedString = Base64.encode(string);
//console.log(encodedString); // Outputs: "SGVsbG8gV29ybGQh"

// Decode the String
//var decodedString = Base64.decode(encodedString);
//console.log(decodedString); // Outputs: "Hello World!"